import Mirador from "mirador/dist/es/src/index";

import imageCropperPlugin from "../../src";

import defaultSettings from "../../../rdv/rdv-ws/projects/mirador-with-plugins/src/default_settings";

var usp = new URLSearchParams(window.location.search);
var manifestUrl = usp.get("manifest");
var searched = usp.get("h");
var locale = usp.get("locale");
var configUrl = usp.get("config");

function get(pathAndQuery, reqInit) {
  reqInit = reqInit || {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  if (reqInit.mode !== 'cors' && !reqInit.credentials) {
    reqInit.credentials = 'include'; // adds cookies!
  }

  return fetch(pathAndQuery, reqInit)
      .then(async function (response) {
        var status = response.status;
        var statusCodeCateory = Math.floor(status / 100);
        if (statusCodeCateory !== 2 && statusCodeCateory !== 3) {
          var payload = {error: "unknown"};
          try {
            payload = await response.json();
          } catch (e) {
            // NOP
          }
          return Promise.reject(new Error("Bad response " + status + " for " + pathAndQuery
              + ": " + response.statusText, payload));
        }
        return response.json();
      })
      .catch(function (ex) {
        console.error('** ERROR ** request failed', ex, "details:", ex.payload);
        return Promise.reject(ex); // DON'T FORGET THIS LINE, OTHERWISE FOLLOWING then() WILL BE TRIGGERED!
      });
}

function initMirador(config) {
  config.id = 'demo';
  config.language = locale;
  config.catalog = [{
    manifestId: manifestUrl,
    provider: "Universitaet Basel"
  }];
  if (!config.windows) {
    config.windows = [];
  }

  let win;
  if (!config.windows || config.windows.length !== 1) {
    win = {};
    if (!config.windows) {
      config.windows = [];
    }
    config.windows.push(win);
  } else {
    win = config.windows[0];
  }
  win.loadedManifest = manifestUrl;
  if (win.imageToolsEnabled === undefined) {
    win.imageToolsEnabled = true;
  }
  if (win.imageToolsOpen === undefined) {
    win.imageToolsOpen = false;
  }
  if (!win.textOverlay) {
    win.textOverlay = {
      enabled: true,
      selectable: true,
      visible: false
    };
  }

  if (!win.thumbnailNavigationPosition) {
    win.thumbnailNavigationPosition = 'far-bottom';
  }
  if (win.maximized === undefined) {
    win.maximized = true;
  }
  win.defaultSearchQuery = searched;

  Mirador.viewer(config, [...imageCropperPlugin]);
}

if (configUrl) {
  get(configUrl).then(function (config) {
    initMirador(config);
  });
} else {
  initMirador(defaultSettings);
}

