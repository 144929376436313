const defaultSettings = {
    "canvasNavigation": {
        "height": 50,
        "width": 50
    },
    "selectedTheme": "light",
    "themes": {
        "dark": {
            "palette": {
                "type": "dark",
                "primary": {
                    "main": "#4db6ac"
                },
                "secondary": {
                    "main": "#4db6ac"
                },
                "shades": {
                    "dark": "#000000",
                    "main": "#424242",
                    "light": "#616161"
                }
            }
        },
        "light": {
            "palette": {
                "type": "light"
            }
        }
    },
    "theme": {
        "palette": {
            "type": "light",
            "primary": {
                "main": "#1967d2"
            },
            "secondary": {
                "main": "#1967d2"
            },
            "shades": {
                "dark": "#eeeeee",
                "main": "#ffffff",
                "light": "#f5f5f5"
            },
            "error": {
                "main": "#b00020"
            },
            "notification": {
                "main": "#ffa224"
            },
            "hitCounter": {
                "default": "#bdbdbd"
            },
            "highlights": {
                "primary": "#ffff00",
                "secondary": "#00BFFF"
            },
            "section_divider": "rgba(0, 0, 0, 0.25)"
        },
        "typography": {
            "body1": {
                "fontSize": "1rem",
                "letterSpacing": "0em",
                "lineHeight": "1.6em"
            },
            "body2": {
                "fontSize": "0.878rem",
                "letterSpacing": "0.015em",
                "lineHeight": "1.6em"
            },
            "button": {
                "fontSize": "0.878rem",
                "letterSpacing": "0.09em",
                "lineHeight": "2.25rem",
                "textTransform": "uppercase"
            },
            "caption": {
                "fontSize": "0.772rem",
                "letterSpacing": "0.033em",
                "lineHeight": "1.6rem"
            },
            "body1Next": {
                "fontSize": "1rem",
                "letterSpacing": "0em",
                "lineHeight": "1.6em"
            },
            "body2Next": {
                "fontSize": "0.878rem",
                "letterSpacing": "0.015em",
                "lineHeight": "1.6em"
            },
            "buttonNext": {
                "fontSize": "0.878rem",
                "letterSpacing": "0.09em",
                "lineHeight": "2.25rem"
            },
            "captionNext": {
                "fontSize": "0.772rem",
                "letterSpacing": "0.33em",
                "lineHeight": "1.6rem"
            },
            "overline": {
                "fontSize": "0.678rem",
                "fontWeight": 500,
                "letterSpacing": "0.166em",
                "lineHeight": "2em",
                "textTransform": "uppercase"
            },
            "h1": {
                "fontSize": "2.822rem",
                "letterSpacing": "-0.015em",
                "lineHeight": "1.2em"
            },
            "h2": {
                "fontSize": "1.575rem",
                "letterSpacing": "0em",
                "lineHeight": "1.33em"
            },
            "h3": {
                "fontSize": "1.383rem",
                "fontWeight": 300,
                "letterSpacing": "0em",
                "lineHeight": "1.33em"
            },
            "h4": {
                "fontSize": "1.215rem",
                "letterSpacing": "0.007em",
                "lineHeight": "1.45em"
            },
            "h5": {
                "fontSize": "1.138rem",
                "letterSpacing": "0.005em",
                "lineHeight": "1.55em"
            },
            "h6": {
                "fontSize": "1.067rem",
                "fontWeight": 400,
                "letterSpacing": "0.01em",
                "lineHeight": "1.6em"
            },
            "subtitle1": {
                "fontSize": "0.937rem",
                "letterSpacing": "0.015em",
                "lineHeight": "1.6em",
                "fontWeight": 300
            },
            "subtitle2": {
                "fontSize": "0.878rem",
                "fontWeight": 500,
                "letterSpacing": "0.02em",
                "lineHeight": "1.75em"
            },
            "useNextVariants": true
        },
        "overrides": {
            "MuiListSubheader": {
                "root": {
                    "&[role=\"presentation\"]:focus": {
                        "outline": 0
                    }
                }
            },
            "MuiTooltip": {
                "tooltipPlacementLeft": {
                    "@media (min-width:600px)": {
                        "margin": 0
                    }
                },
                "tooltipPlacementRight": {
                    "@media (min-width:600px)": {
                        "margin": 0
                    }
                },
                "tooltipPlacementTop": {
                    "@media (min-width:600px)": {
                        "margin": 0
                    }
                },
                "tooltipPlacementBottom": {
                    "@media (min-width:600px)": {
                        "margin": 0
                    }
                }
            },
            "MuiTouchRipple": {
                "childPulsate": {
                    "animation": "none"
                },
                "rippleVisible": {
                    "animation": "none"
                }
            }
        },
        "props": {
            "MuiButtonBase": {
                "disableTouchRipple": true
            },
            "MuiLink": {
                "underline": "always"
            }
        }
    },
    "language": "en",
    "availableLanguages": {
        "ar": "العربية",
        "de": "Deutsch",
        "en": "English",
        "fr": "Français",
        "ja": "日本語",
        "nl": "Nederlands",
        "pt-BR": "Português do Brasil",
        "zh-CN": "中文(简体)",
        "zh-TW": "中文(繁體)",
        "it": "Italiano",
        "sr": "Српски"
    },
    "annotations": {
        "htmlSanitizationRuleSet": "iiif",
        "filteredMotivations": [
            "oa:commenting",
            "oa:tagging",
            "sc:painting",
            "commenting",
            "tagging"
        ]
    },
    "classPrefix": "mirador",
    "displayAllAnnotations": false,
    "requests": {
        "preprocessors": [],
        "postprocessors": []
    },
    "translations": {},
    "window": {
        "allowClose": true,
        "allowFullscreen": false,
        "allowMaximize": true,
        "allowTopMenuButton": true,
        "allowWindowSideBar": true,
        "authNewWindowCenter": "parent",
        "defaultSideBarPanel": "info",
        "defaultSidebarPanelHeight": 201,
        "defaultSidebarPanelWidth": 235,
        "defaultView": "single",
        "hideWindowTitle": false,
        "showLocalePicker": false,
        "sideBarOpenByDefault": true,
        "panels": {
            "info": true,
            "attribution": true,
            "canvas": true,
            "annotations": true,
            "search": true
        },
        textOverlay: {
            enabled: true,
            selectable: true,
            visible: false
        },
        "views": [
            {
                "key": "single",
                "behaviors": [
                    "individuals"
                ]
            },
            {
                "key": "book",
                "behaviors": [
                    "paged"
                ]
            },
            {
                "key": "scroll",
                "behaviors": [
                    "continuous"
                ]
            },
            {
                "key": "gallery"
            }
        ],
    },
    "windows": [],
    "thumbnailNavigation": {
        "defaultPosition": "off",
        "height": 130,
        "width": 100
    },
    "workspaceControlPanel": {
        "enabled": true
    },
    "galleryView": {
        "height": 120,
        "width": null
    },
    "osdConfig": {
        "alwaysBlend": false,
        "blendTime": 0.1,
        "preserveImageSizeOnResize": true,
        "preserveViewport": true,
        "showNavigationControl": false
    },
    "miradorSharePlugin": {
        "embedOption": {
            "enabled": false
        },
        "dragAndDropInfoLink": "https://library.stanford.edu/projects/international-image-interoperability-framework/viewers",
        "shareLink": {
            "enabled": false
        }
    },
};

export default defaultSettings;
